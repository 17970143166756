<template>
	<b-form-group :label="`${label} *`" label-class="albert-label-form albert-fs-13" style="display: inline-block; position: relative; width: 100%">
		<input
			v-model="key_user"
			type="text"
			required="required"
			:placeholder="placeholder"
			class="albert-fontInputPlaceholder albert-width100per albert-formInputOval"
			@input="searchUser"
		/>
		<i v-if="key_user !== ''" class="fa fa-times-circle albert-bgCol albert_removeThisField albert-icon-absolute text-danger" @click="onReset" />
		<b-list-group v-if="show_list">
			<b-list-group-item href="#" v-for="(option, index) in options" :key="index" @click="onClickValue(option)">
				{{ option.name + ' ' + option.firstName }}
			</b-list-group-item>
		</b-list-group>
	</b-form-group>
</template>

<script>
import { getUsers } from '@/cruds/users.crud';

export default {
	name: 'InputSelect',
	props: {
		label: { type: String, required: true },
		placeholder: { type: String, required: true },
		initData: { type: Object, default: null, required: true }
	},

	data() {
		return {
			users: [],
			options: [],
			key_user: '',
			show_list: false
		};
	},
	async mounted() {
		this.users = await getUsers({ myProject: true });
	},
	methods: {
		searchUser() {
			if (this.key_user.length > 1) {
				this.show_list = true;
				const reg = new RegExp('^' + this.key_user, 'i');
				this.options = this.users.filter((user) => {
					return user.name.search(reg) !== -1 || user.firstName.search(reg) !== -1;
				});
			} else {
				this.options = [];
				this.show_list = false;
			}
		},
		onClickValue(user) {
			this.key_user = user.name + ' ' + user.firstName;
			this.$emit('user:selected', { user });
			this.show_list = false;
		},
		onReset() {
			this.key_user = '';
			this.$emit('user:selected', { user: null });
		}
	},
	watch: {
		initData(val) {
			if (val.name && val.name !== '' && val.name && val.firstName !== '') {
				this.key_user = val.name + ' ' + val.firstName;
			} else {
				this.key_user = '';
			}
		}
	}
};
</script>

<style scoped>
.list-group {
	z-index: 1000;
	position: absolute;
	width: 82%;
	overflow-y: scroll;
	height: 150px;
	left: 30px;
	background: white;
}
.list-group a:last-child {
	border-bottom: none;
}
.list-group a {
	border-left: none;
	border-right: none;
}
</style>
