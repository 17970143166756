var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "errorContainer",
        { attrs: { error: _vm.erreur, warning: _vm.warning } },
        [
          _c(
            "div",
            [
              _c(
                "b-row",
                { staticClass: "albert-justify-content-md-center mt-3 mb-3" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "p-5 text-left albert-bg-modal",
                      staticStyle: { "margin-left": "0" },
                      attrs: {
                        cols: _vm.$screen.width >= 1295 ? 8 : 12,
                        offset: _vm.$screen.width >= 1295 ? 2 : 0,
                      },
                    },
                    [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                              return _vm.updatingAlbertProd.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 8 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          1,
                                          "Production name"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.prodName,
                                            expression: "albert_prod.prodName",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          type: "text",
                                          placeholder: _vm.FormMSG(
                                            1,
                                            "Production name"
                                          ),
                                          required: "required",
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.prodName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "prodName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 4 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          2,
                                          "Period Name"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.periodName,
                                            expression:
                                              "albert_prod.periodName",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          type: "text",
                                          required: "required",
                                          placeholder: _vm.FormMSG(
                                            24,
                                            "E.g. S1, series 1, 2021"
                                          ),
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.periodName,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "periodName",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 4 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          3,
                                          "Production type"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.albert_prod.prodType,
                                              expression:
                                                "albert_prod.prodType",
                                            },
                                          ],
                                          staticClass:
                                            "albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select",
                                          attrs: { required: "required" },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.albert_prod,
                                                "prodType",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.menusProdType,
                                          function (menu, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value:
                                                    menu.value === 0
                                                      ? ""
                                                      : menu.value,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      menu.value !== 0
                                                        ? menu.text
                                                        : _vm.FormMSG(
                                                            25,
                                                            "Please select"
                                                          )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 4 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(4, "Genre")} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.albert_prod.genre,
                                              expression: "albert_prod.genre",
                                            },
                                          ],
                                          staticClass:
                                            "albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select",
                                          attrs: { required: "required" },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.albert_prod,
                                                "genre",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.menusGenre,
                                          function (menu, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value:
                                                    menu.value === 0
                                                      ? ""
                                                      : menu.value,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      menu.value !== 0
                                                        ? menu.text
                                                        : _vm.FormMSG(
                                                            25,
                                                            "Please select"
                                                          )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 4 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          5,
                                          "Production Method"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.albert_prod.prodMethod,
                                              expression:
                                                "albert_prod.prodMethod",
                                            },
                                          ],
                                          staticClass:
                                            "albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select",
                                          attrs: { required: "required" },
                                          on: {
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.albert_prod,
                                                "prodMethod",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.menusProdMethod,
                                          function (menu, index) {
                                            return _c(
                                              "option",
                                              {
                                                key: index,
                                                domProps: {
                                                  value:
                                                    menu.value === 0
                                                      ? ""
                                                      : menu.value,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      menu.value !== 0
                                                        ? menu.text
                                                        : _vm.FormMSG(
                                                            25,
                                                            "Please select"
                                                          )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "albert-fontFromGroupTitle form-group-title",
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(6, "Production dates")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 935 ? 3 : 6,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          7,
                                          "Pre-Production start date"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.preProdStart,
                                            expression:
                                              "albert_prod.preProdStart",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          type: "date",
                                          required: "required",
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.preProdStart,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "preProdStart",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 935 ? 3 : 6,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          8,
                                          "Production start date"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.prodStart,
                                            expression: "albert_prod.prodStart",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          type: "date",
                                          required: "required",
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.prodStart,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "prodStart",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 935 ? 3 : 6,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          9,
                                          "Post-Production start date"
                                        ),
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.albert_prod.postProdStart,
                                            expression:
                                              "albert_prod.postProdStart",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: { type: "date" },
                                        domProps: {
                                          value: _vm.albert_prod.postProdStart,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "postProdStart",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 935 ? 3 : 6,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          10,
                                          "Post-Production end date"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.postProdEnd,
                                            expression:
                                              "albert_prod.postProdEnd",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          type: "date",
                                          required: "required",
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.postProdEnd,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "postProdEnd",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 935 ? 3 : 6,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.FormMSG(
                                          11,
                                          "TX / Release Date"
                                        ),
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.albert_prod.txReleaseDate,
                                            expression:
                                              "albert_prod.txReleaseDate",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: { type: "date" },
                                        domProps: {
                                          value: _vm.albert_prod.txReleaseDate,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "txReleaseDate",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "albert-fontFromGroupTitle" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(12, "Additional Details")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "p-1",
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c("div", {
                                    staticClass:
                                      "albert-fs-13 font-weight-bold",
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.FormMSG(
                                          13,
                                          "How many episodes/features are you recording?<br> (For a single feature, enter ‘1’) *"
                                        )
                                      ),
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 3 : 12,
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.albert_prod.numEpisode,
                                        expression: "albert_prod.numEpisode",
                                      },
                                    ],
                                    staticClass:
                                      "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                    attrs: {
                                      type: "text",
                                      required: "required",
                                      placeholder: _vm.FormMSG(26, "E.g: 1"),
                                    },
                                    domProps: {
                                      value: _vm.albert_prod.numEpisode,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.albert_prod,
                                          "numEpisode",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "p-3",
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "albert-fs-13 font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              14,
                                              "Running time of each episode/feature (mins)?"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t\t*\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 3 : 12,
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.albert_prod.runningTime,
                                        expression: "albert_prod.runningTime",
                                      },
                                    ],
                                    staticClass:
                                      "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                    attrs: {
                                      type: "text",
                                      required: "required",
                                      placeholder: _vm.FormMSG(27, "E.g: 30"),
                                    },
                                    domProps: {
                                      value: _vm.albert_prod.runningTime,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.albert_prod,
                                          "runningTime",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "p-3",
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "albert-fs-13 font-weight-bold",
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              15,
                                              "What is the budget for this production?"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 3 : 6,
                                  },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.albert_prod.prodBudget,
                                        expression: "albert_prod.prodBudget",
                                      },
                                    ],
                                    staticClass:
                                      "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.FormMSG(
                                        28,
                                        "E.g: 1 000 000"
                                      ),
                                    },
                                    domProps: {
                                      value: _vm.albert_prod.prodBudget,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.albert_prod,
                                          "prodBudget",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 3 : 6,
                                  },
                                },
                                [
                                  _c(
                                    "select",
                                    {
                                      staticClass:
                                        "albert-fontInputPlaceholder albert-width99per albert-formInputOval albert-select",
                                      attrs: { required: "required" },
                                    },
                                    _vm._l(_vm.moneys, function (money, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: {
                                            value: money,
                                            selected:
                                              money === "GBP" ? "selected" : "",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(money) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "albert-fontFromGroupTitle" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(16, "Production Ownership")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          17,
                                          "Company name"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.prodCompany,
                                            expression:
                                              "albert_prod.prodCompany",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          required: "required",
                                          type: "text",
                                          placeholder: _vm.FormMSG(
                                            17,
                                            "Company name"
                                          ),
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.prodCompany,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "prodCompany",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          18,
                                          "Broadcaster / Funder"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.funder,
                                            expression: "albert_prod.funder",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          required: "required",
                                          type: "text",
                                          placeholder: _vm.FormMSG(
                                            18,
                                            "Broadcaster / Funder"
                                          ),
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.funder,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "funder",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { staticClass: "albert-fontFromGroupTitle" },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(
                                        _vm.FormMSG(19, "Associated Users")
                                      ) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c("InputSelect", {
                                    attrs: {
                                      label: _vm.FormMSG(20, "User"),
                                      placeholder: _vm.FormMSG(20, "User"),
                                      "init-data": _vm.albert_prod.user,
                                    },
                                    on: { "user:selected": _vm.updateUser },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: `${_vm.FormMSG(
                                          21,
                                          "Reviewer"
                                        )} *`,
                                        "label-class":
                                          "albert-label-form albert-fs-13",
                                        "label-for": "supplier-input-iban",
                                      },
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.albert_prod.reviewer,
                                            expression: "albert_prod.reviewer",
                                          },
                                        ],
                                        staticClass:
                                          "albert-fontInputPlaceholder albert-width99per albert-formInputOval",
                                        attrs: {
                                          type: "text",
                                          required: "",
                                          placeholder: _vm.FormMSG(
                                            21,
                                            "Reviewer"
                                          ),
                                        },
                                        domProps: {
                                          value: _vm.albert_prod.reviewer,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.albert_prod,
                                              "reviewer",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            { staticClass: "mt-5" },
                            [
                              _c(
                                "b-col",
                                {
                                  class: {
                                    "text-center": _vm.$screen.width <= 700,
                                  },
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "albert-modalBtn",
                                      attrs: { type: "submit" },
                                    },
                                    [
                                      !_vm.form_busy
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  22,
                                                  "SAVE PRODUCTION"
                                                )
                                              ) + " "
                                            ),
                                            _c("i", {
                                              staticClass: "fa fa-plus",
                                            }),
                                          ])
                                        : _c(
                                            "span",
                                            [
                                              _c("b-spinner", {
                                                attrs: { label: "Spinning" },
                                              }),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      202,
                                                      "UPDATE IN PROGRESS"
                                                    )
                                                  )
                                              ),
                                            ],
                                            1
                                          ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "form-group text-right albert-fontSmallHeader albert-verticalMarginAuto",
                                  class: {
                                    "text-center mt-3":
                                      _vm.$screen.width <= 700,
                                  },
                                  attrs: {
                                    cols: _vm.$screen.width > 700 ? 6 : 12,
                                  },
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.clearAll.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-times albert-bgCol",
                                      }),
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(_vm.FormMSG(23, "CLEAR")) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }