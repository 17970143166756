var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-form-group",
    {
      staticStyle: {
        display: "inline-block",
        position: "relative",
        width: "100%",
      },
      attrs: {
        label: `${_vm.label} *`,
        "label-class": "albert-label-form albert-fs-13",
      },
    },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.key_user,
            expression: "key_user",
          },
        ],
        staticClass:
          "albert-fontInputPlaceholder albert-width100per albert-formInputOval",
        attrs: {
          type: "text",
          required: "required",
          placeholder: _vm.placeholder,
        },
        domProps: { value: _vm.key_user },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.key_user = $event.target.value
            },
            _vm.searchUser,
          ],
        },
      }),
      _vm.key_user !== ""
        ? _c("i", {
            staticClass:
              "fa fa-times-circle albert-bgCol albert_removeThisField albert-icon-absolute text-danger",
            on: { click: _vm.onReset },
          })
        : _vm._e(),
      _vm.show_list
        ? _c(
            "b-list-group",
            _vm._l(_vm.options, function (option, index) {
              return _c(
                "b-list-group-item",
                {
                  key: index,
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.onClickValue(option)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n\t\t\t" +
                      _vm._s(option.name + " " + option.firstName) +
                      "\n\t\t"
                  ),
                ]
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }