/**
 * GRAPH OF CRUD FOR ALBERT PROD
 */
import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';

export const getAlbertProd = async () => {
	try {
		const response = await apollo.query({
			query: gql`
				query GetAlbertProd {
					GetAlbertProd {
						id
						prodName
						periodName
						prodType
						genre
						prodMethod
						preProdStart
						prodStart
						postProdStart
						postProdEnd
						txReleaseDate
						numEpisode
						runningTime
						prodBudget
						prodCompany
						funder
						userId
						reviewer
						user {
							name
							firstName
						}
					}
				}
			`,
			fetchPolicy: 'no-cache'
		});

		const { GetAlbertProd } = response.data;
		return GetAlbertProd;
	} catch (e) {
		console.log(e.message);
	}
};

export const updateAlbertProd = async (alberProdInput) => {
	try {
		const response = await apollo.mutate({
			mutation: gql`
				mutation UpdAlbertProd($updAlbertProd: AlbertProdInput!) {
					UpdAlbertProd(UpdAlbertProd: $updAlbertProd) {
						id
						prodName
						periodName
						prodType
						genre
						prodMethod
						preProdStart
						prodStart
						postProdStart
						postProdEnd
						txReleaseDate
						numEpisode
						runningTime
						prodBudget
						prodCompany
						funder
						userId
						reviewer
						user {
							name
							firstName
						}
					}
				}
			`,
			variables: {
				updAlbertProd: alberProdInput
			},
			fetchPolicy: 'no-cache'
		});
		const { UpdAlbertProd } = response.data;
		return UpdAlbertProd;
	} catch (e) {
		console.log(e.message);
	}
};
